import React from "react"
import { Table } from "react-bootstrap"
import { ClientOrderQuery } from "../generated/graphql"
import { LineItemSnapshot } from "../types/LineItemSnapshot"
import Loading from "./Loading"

const OrderItemTable: React.FC<{
  order: ClientOrderQuery["order"] | undefined
}> = ({ order }) => {
  const isStandardSize = (size: string | undefined | null) => {
    const normalSizeArray = ["Small", "Medium", "Large", "X-Large"]
    if (size) return normalSizeArray.includes(size)
    return false
  }
  const snapshots: Record<string, LineItemSnapshot> = {}

  if (order?.lineItems && order?.lineItems?.length > 0) {
    order.lineItems.forEach((lineItem) => {
      const snapshot = lineItem?.snapshot
      const purchasableId = lineItem?.id
      if (snapshot && snapshot != "" && purchasableId) {
        snapshots[purchasableId] = JSON.parse(snapshot)
      }
    })
  }

  return (
    <>
      {order?.lineItems?.length ? (
        <>
          <div className="overflow-scroll">
            {Object.keys(snapshots).length === 0 ? (
              <div className="m-5">
                <Loading />
              </div>
            ) : (
              <Table className="responsive-table m-0">
                <thead>
                  <tr>
                    <th
                      className="text-nowrap pe-3"
                      style={{ minWidth: "180px" }}
                    >
                      {"Item"}
                    </th>
                    <th
                      className="text-nowrap pe-3"
                      style={{ minWidth: "150px" }}
                    >
                      {"Size"}
                    </th>
                    <th
                      className="text-nowrap pe-3"
                      style={{ minWidth: "150px" }}
                    >
                      {"Unit amount"}
                    </th>
                    <th className="text-nowrap pe-3">{"Qty"}</th>
                    <th className="text-nowrap pe-3">{"Total"}</th>
                  </tr>
                </thead>
                <tbody>
                  {order?.lineItems?.map((lineItem, i) => {
                    if (lineItem && lineItem.id) {
                      const snapshot = snapshots[lineItem.id]
                      return (
                        <tr key={i} className="my-2">
                          <td>{snapshot?.product?.title}</td>
                          {/* // get the size via the product sizes, need gender and variant id */}
                          <td>
                            {isStandardSize(snapshot?.title)
                              ? snapshot?.title
                              : (lineItem.purchasable?.product?.__typename ===
                                  "incontinenceProducts_Product" ||
                                  lineItem.purchasable?.product?.__typename ===
                                    "inserts_Product" ||
                                  lineItem.purchasable?.product?.__typename ===
                                    "otherCarersCenterProductType_Product") &&
                                lineItem.purchasable.product.volume
                                ? lineItem.purchasable.product.volume + "ml"
                                : "One Size"}
                          </td>
                          <td>{`$${
                            snapshot?.product?.defaultPrice &&
                            Number(snapshot.product.defaultPrice).toFixed(2)
                          }`}</td>
                          <td>{lineItem.qty}</td>
                          <td>{`$${
                            lineItem.subtotal && lineItem.subtotal.toFixed(2)
                          }`}</td>
                        </tr>
                      )
                    }
                  })}
                </tbody>
              </Table>
            )}
          </div>
          <Table className="responsive-table m-0">
            <tfoot>
              <tr className="text-end">
                <td>{"Ex. GST:"}</td>
                <td style={{ width: "0px" }}>
                  <span>
                    {"$"}
                    {order.itemSubtotal && order.itemSubtotal.toFixed(2)}
                  </span>
                </td>
              </tr>
              <tr className="text-end">
                <td>{"Shipping:"}</td>
                <td style={{ width: "0px" }}>
                  <span>
                    {"$"}
                    {order.totalShippingCost &&
                      order.totalShippingCost.toFixed(2)}
                  </span>
                </td>
              </tr>
              <tr className="text-end">
                <td>{"GST:"}</td>
                <td style={{ width: "0px" }}>
                  <span>
                    {"$"}
                    {order.totalTax && order.totalTax.toFixed(2)}
                  </span>
                </td>
              </tr>
              {!!order?.totalDiscount &&
              <tr className="text-end">
                <td>{"Discount:"}</td>
                <td style={{ width: "0px" }}>
                  <span>
                    {"$"}
                    {order.totalDiscount.toFixed(2)}
                  </span>
                </td>
              </tr>
              }
              <tr className="grand-total-row text-end">
                <td>
                  <b>{"Grand Total:"}</b>
                </td>
                <td style={{ width: "0px" }}>
                  <span>
                    {"$"}
                    {order.total && order.total.toFixed(2)}
                  </span>
                </td>
              </tr>
            </tfoot>
          </Table>
        </>
      ) : (
        "No line items found for this order."
      )}
    </>
  )
}

export default OrderItemTable
