const searchObjectRecursively = (
  object: { [key: string]: any },
  term: string,
) => {
  let found = false
  const regex = new RegExp(escapeRegex(term.toLowerCase()))

  Object.values(object).forEach((value) => {
    if (!found && value) {
      if (typeof value === "object") {
        found = searchObjectRecursively(value, term)
      } else if (typeof value === "string") {
        found = regex.test(value.toLowerCase())
      } else if (typeof value === "number") {
        found = regex.test(String(value).toLowerCase())
      }
    }
  })
  return found
}

const escapeRegex = (string: string) => {
  return string.replace(/[/\-\\^$*+?.()|[\]{}]/g, "\\$&")
}

export default searchObjectRecursively
