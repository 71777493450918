import { ApolloError, NetworkStatus, useReactiveVar } from "@apollo/client"
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { Col, Row } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import cartVar from "../apollo/vars/cartVar"
import {
  CartCostQueryVariables,
  PaginatedOrdersDocument,
  ProcessOrderMutation,
  useCartCostQuery,
  useCreateOrderFromCartMutation,
  useProcessOrderMutation,
} from "../generated/graphql"
import CormieSection from "../layouts/CormieSection"
import resetCart from "../utils/cart/resetCart"
import Loading from "./Loading"
import MainButton from "./MainButton"
import organisationIdVar from "../apollo/vars/organisationIdVar"
import isAdminVar from "../apollo/vars/isAdminVar"

const CartOrderSummary: React.FC<{
  isAddressValid: boolean
  isPaymentDetailsValid: boolean
  optionalPoNumber?: string
  optionalDeliveryInstructions?: string
}> = ({
  isAddressValid,
  isPaymentDetailsValid,
  optionalPoNumber,
  optionalDeliveryInstructions,
}) => {
  useReactiveVar(cartVar)
  const organisationId = organisationIdVar()
  const isAdmin = isAdminVar()

  const [showValidationErrors, setShowValidationErrors] = useState<{
    show: boolean
    message: string
  }>({ show: false, message: "" })
  const [orderProcessing, setOrderProcessing] = useState<boolean>(false)
  const [inputCouponCode, setInputCouponCode] = useState<string>("")
  const [orderCouponCode, setOrderCouponCode] = useState<string>("")
  const cart = cartVar()
  const navigate = useNavigate()

  const purchasables: CartCostQueryVariables["purchasables"] = Object.keys(
    cart.cart,
  ).map((uid) => {
    return { id: uid, qty: cart.cart[uid] }
  })

  const { data: costData, loading: costLoading, refetch, networkStatus } = useCartCostQuery({
    variables: {
      purchasables: purchasables,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if(data.temporaryCart?.couponCode) {
        setOrderCouponCode(data.temporaryCart.couponCode)
      }
    }
  })

  const [createOrderFromCart, { error: createOrderError }] =
    useCreateOrderFromCartMutation({
      variables: {
        purchasables: purchasables,
        userId: cart.uid ? cart.uid : "",
        couponCode: orderCouponCode,
      },
    })

  const [processOrder] = useProcessOrderMutation()

  const paginatedOrdersDefaultVariables = {
    allOrganisations: isAdmin,
    limit: 25,
    offset: 0,
    organisationId: isAdmin ? "" : organisationId,
    routeTags: [],
    search: "",
    sortAscending: false,
    sortField: "date",
  }

  const handleCouponCodeChange = (e: React.ChangeEvent<any>) => {
    setInputCouponCode(e.target.value)
  }

  return (
    <CormieSection className="px-4">
      {(
        costLoading &&
        (networkStatus != NetworkStatus.refetch &&
         networkStatus != NetworkStatus.setVariables)
      ) ? (
          <Loading />
        ) : (
          <>
            <Row className="my-3">
              <Col>
                <p className="h5">
                  <b>{"Order summary"}</b>
                </p>
              </Col>
            </Row>
            <hr></hr>
            <Row className="mt-4 mb-3">
              <Col>
                <p className="h6">
                  <b>{"Ex. GST"}</b>
                </p>
              </Col>
              <Col className="text-end">
                <p className="h6">
                  <b>
                    <span
                      className="align-top"
                      style={{
                        fontSize: "11px",
                      }}
                    >
                      {"$"}
                    </span>
                    {costData?.temporaryCart?.itemSubtotal
                      ? costData.temporaryCart.itemSubtotal.toFixed(2)
                      : "0.00"}
                  </b>
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="h6">{"GST"}</p>
              </Col>
              <Col className="text-end">
                <p className="h6">
                  <b>
                    <span
                      className="align-top"
                      style={{
                        fontSize: "10px",
                      }}
                    >
                      {"$"}
                    </span>
                    {costData?.temporaryCart?.totalTax
                      ? costData.temporaryCart.totalTax.toFixed(2)
                      : "0.00"}
                  </b>
                </p>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col>
                <p className="h6">{"Shipping"}</p>
              </Col>
              <Col className="text-end">
                <p className="h6">
                  <b>
                    <span
                      className="align-top"
                      style={{
                        fontSize: "10px",
                      }}
                    >
                      {"$"}
                    </span>
                    {costData?.temporaryCart?.totalShippingCost
                      ? costData.temporaryCart.totalShippingCost.toFixed(2)
                      : "0.00"}
                  </b>
                </p>
              </Col>
            </Row>
            {!!costData?.temporaryCart?.totalDiscount &&
          <Row>
            <Col>
              <p className="h6">{"Discount"}</p>
            </Col>
            <Col className="text-end">
              <p className="h6">
                <b>
                  <span
                    className="align-top"
                    style={{
                      fontSize: "10px",
                    }}
                  >
                    {"$"}
                  </span>
                  {costData.temporaryCart.totalDiscount < 0 // Discounts come through as minus amounts (-1.50, for example)
                    ? costData.temporaryCart.totalDiscount.toFixed(2)
                    : "0.00"}
                </b>
              </p>
            </Col>
          </Row>
            }
            {(!!costData?.temporaryCart?.couponCode && (costData?.temporaryCart?.totalDiscount || costData?.temporaryCart?.hasFreeShippingForOrder)) &&
          <Row>
            <Col>
              <p className="h6">{"Coupon code"}</p>
            </Col>
            <Col className="text-end">
              <p className="h6">
                <b>
                  {costData.temporaryCart.couponCode}
                </b>
              </p>
            </Col>
          </Row>
            }
            <hr></hr>
            <Row className="mb-3">
              <Col className="d-flex align-items-center">
                <p className="h4 m-0">
                  <b>{"Total"}</b>
                </p>
              </Col>
              <Col className="d-flex align-items-center justify-content-end">
                <p className="h1 m-0">
                  <b>
                    <span
                      className="align-top"
                      style={{
                        fontSize: "20px",
                      }}
                    >
                      {"$"}
                    </span>
                    {costData?.temporaryCart?.total
                      ? costData.temporaryCart.total.toFixed(2)
                      : "0.00"}
                  </b>
                </p>
              </Col>
            </Row>
            <hr></hr>
            <Row className="justify-content-center">
              <Col xs={12} className="p-0 pe-2 align-content-center">
                <b>{"Coupon code:"}</b>
              </Col>
              <Col xs={10} xxl={9} className="p-0 align-content-center">
                <input
                  aria-label="couponCode"
                  type="text"
                  onChange={handleCouponCodeChange}
                  className="form-control cormie-form-control"
                  value={inputCouponCode}
                />
              </Col>
              <Col xs={2} xxl={3} className="p-0 align-content-center text-center">
                <MainButton
                  className="m-0 py-1 px-3 ms-1"
                  disabled={
                    networkStatus === NetworkStatus.refetch
                    || networkStatus === NetworkStatus.setVariables
                    || inputCouponCode === ""
                  }
                  faded={inputCouponCode === ""}
                  nonReactive={inputCouponCode === ""}
                  onClick={async () => {
                    // Not super well documented, but if you call refetch with variables,
                    // then the NetworkStatus will = setVariables, not refetch. Calling
                    // refetch with the same variables as the current query results in
                    // NetworkStatus refetch. Annoyingly, Apollo will scrub the old data
                    // in the case of NetworkStatus.setVariables ¯\_(ツ)_/¯
                    await refetch({
                      purchasables: purchasables,
                      couponCode: inputCouponCode
                    })
                    setInputCouponCode("")
                  }}
                >
                  {networkStatus === NetworkStatus.refetch || networkStatus === NetworkStatus.setVariables ? <Loading/> : "Apply"}
                </MainButton>
              </Col>
              {costData?.temporaryCart?.couponRemoved &&
              <Col xs={12}>
                <span className="text-danger">Invalid coupon code</span>
              </Col>
              }

            </Row>

            <Row className="justify-content-center">
              <Col className="col-auto">
                <MainButton
                  color="blue"
                  faded={
                    showValidationErrors.show ||
                    (!costData?.temporaryCart?.total && !costData?.temporaryCart?.totalDiscount)
                  }
                  className="my-3 px-5 py-2"
                  disabled={
                    showValidationErrors.show ||
                    orderProcessing ||
                    (!costData?.temporaryCart?.total && !costData?.temporaryCart?.totalDiscount) ||
                    networkStatus === NetworkStatus.refetch ||
                    networkStatus === NetworkStatus.setVariables
                  }
                  onClick={async () => {
                  // The "main" function for processing a cart
                  // Probably could be given its own function.

                    if (!isAddressValid || !isPaymentDetailsValid) {
                    // if the address or payment details arent valid, stop here
                    // and display prescribed errors
                      setShowValidationErrors({
                        ...showValidationErrors,
                        show: true,
                      })
                    } else {
                    // We start to process the order
                      setOrderProcessing(true)
                      // We create an order from the local cart and get back a number
                      const { data: createOrderData } =
                      await createOrderFromCart()
                      if (
                        createOrderData?.createOrderFromPurchasables?.number &&
                      !createOrderError
                      ) {
                      // We got an order back and no error was created, so we attempt
                      // to process the order

                        let processOrderData:
                        | ProcessOrderMutation
                        | null
                        | undefined
                        try {
                          const { data } = await processOrder({
                            variables: {
                              number:
                              createOrderData.createOrderFromPurchasables
                                ?.number,
                              optionalPoNumber: optionalPoNumber,
                              optionalDeliveryInstructions:
                              optionalDeliveryInstructions,
                            },
                            // The below way of refetching queries is "legacy" according
                            // to apollo. The reality is that at the time of writing,
                            // there is no "easy" way to refetch a query that isn't
                            // currently active, ie mounted. Apollo suggests updating the
                            // cache directly, which is a complete mindbend.
                            // References:
                            // https://github.com/apollographql/apollo-client/issues/5419
                            // https://www.apollographql.com/docs/react/caching/cache-interaction/#examples
                            refetchQueries: [
                            // Base all orders query
                              {
                                query: PaginatedOrdersDocument,
                                variables: paginatedOrdersDefaultVariables,
                              },
                              // Base user orders query
                              {
                                query: PaginatedOrdersDocument,
                                variables: {
                                  ...paginatedOrdersDefaultVariables,
                                  carerId: "",
                                  clientId: cart.uid,
                                },
                              },
                            ],
                          })
                          processOrderData = data

                          if (processOrderData?.transactOrder) {
                          // cart was successfully processed!
                          // navigate the user to the newly created order
                            navigate(
                              `/orders/${createOrderData.createOrderFromPurchasables?.id}`,
                            )
                            // reset the cart
                            resetCart()
                          } else {
                          // we could not process the order number :[
                          // support@atomix.com.au gets an email
                            setShowValidationErrors({
                              show: true,
                              message: "Unable to process order number.",
                            })
                          }
                        } catch (error: ApolloError | any) {
                          if (error instanceof ApolloError) {
                            setShowValidationErrors({
                              show: true,
                              message: error.message,
                            })
                          } else {
                            setShowValidationErrors({
                              show: true,
                              message:
                              "An unknown error occurred when processing the order.",
                            })
                          }
                        }
                      } else {
                        setShowValidationErrors({
                          show: true,
                          message: "Unable to create order number from cart.",
                        })
                      }
                      setOrderProcessing(false)
                    }
                  }}
                >
                  {orderProcessing ? (
                    <Loading />
                  ) : (
                    <span
                      className={
                        showValidationErrors.show ||
                        (!costData?.temporaryCart?.total && !costData?.temporaryCart?.totalDiscount)
                          ? "opacity-50"
                          : ""
                      }
                    >
                    SUBMIT ORDER
                    </span>
                  )}
                </MainButton>
              </Col>
            </Row>
            {showValidationErrors.show && (
              <>
                {!isAddressValid && (
                  <>
                    <Row>
                      <Col>
                        <a
                          onClick={() =>
                            navigate(`/customers/${cart.uid}/edit-customer`)
                          }
                          style={{ color: "red" }}
                          className="text-center"
                        >
                          <u>
                            <b color={"red"}>
                              <FontAwesomeIcon
                                icon={faChevronCircleRight}
                                className={"me-2"}
                              />
                              {"Client address is invalid, please update."}
                            </b>
                          </u>
                        </a>
                      </Col>
                    </Row>
                  </>
                )}
                {!isPaymentDetailsValid && (
                  <>
                    <Row>
                      <Col>
                        <a
                          onClick={() =>
                            navigate(`/customers/${cart.uid}/edit-customer`)
                          }
                          style={{ color: "red" }}
                          className="text-center"
                        >
                          <u>
                            <b>
                              <FontAwesomeIcon
                                icon={faChevronCircleRight}
                                className={"me-2"}
                              />
                              {
                                "Client payment details are invalid, please update."
                              }
                            </b>
                          </u>
                        </a>
                      </Col>
                    </Row>
                  </>
                )}
                {isAddressValid && isPaymentDetailsValid && (
                  <>
                    <Row>
                      <Col className="text-center">
                        <u>
                          {showValidationErrors.message
                            ? showValidationErrors.message
                            : "Unable to process order"}
                        </u>
                      </Col>
                    </Row>
                  </>
                )}
              </>
            )}
          </>
        )}
    </CormieSection>
  )
}

export default CartOrderSummary
